<template>
  <section id="con" class="parchment py-5">
    <div class="section">
      <!--      <div class="archive-warning mb-5">-->
      <!--        <p></p>-->
      <!--        <h3>Archiv!</h3>-->
      <!--        <p>-->
      <!--          Du befindest dich aktuell in unserem Archiv.-->
      <!--          Die Informationen hier beziehen sich auf eine alte Con und die <b>Anmeldung ist nicht mehr geöffnet!</b>-->
      <!--        </p>-->
      <!--      </div>-->
      <h1 class="mb-1">Blutgetränkte Erde</h1>
      <h3 class="mt-1">Schlacht um Lichtstedt 4</h3>
      <p></p>
      <h3>IT-Beschreibung</h3>
      <p>
        <i>
          Einst war die Insel die Domäne einer mächtigen Vampirin, deren
          Blutdurst und Macht die Insel in Angst und Schrecken versetzte. Doch
          ihr Fall kam plötzlich und brutal, und ihr Körper versank in den
          tiefen Katakomben der Burg. Nun werden die Inseln von einem ehemaligen
          Diener dieser Vampirin wieder heimgesucht, nachdem das Imperium das
          Gebiet nicht sichern konnte. Die instabile Koalition, die dem Imperium
          trotzen konnte, war nicht imstande den Aufstieg des ehemaligen
          Vampirschergen zu erkennen, geschweigedenn zu verhindern.
        </i>
      </p>
      <p>
        <i>
          Valgor, der ehemalige Diener und mittlerweile Meister der dunklen
          Magie, sah seine Chance. Er wollte die Vampirin wiedererwecken. Nicht
          um ihr zu dienen, sondern um ihre Macht zu stehlen und sich selbst zum
          Herrscher der Insel zu erheben. Mit ihrer Macht, seinen Anhängern und
          einem Heer aus Untoten würde er die Insel beherrschen und darüber
          hinaus seinen Einfluss auf die Alte Welt ausdehnen.
        </i>
      </p>
      <p>
        <i>
          Die Vorbereitungen waren abgeschlossen. Das Ritual sollte in der
          finstersten Stunde der Nacht stattfinden. Doch Valgors Pläne blieben
          nicht unbemerkt. Eine Gruppe tapferer Abenteurer landete auf der
          Insel. Sie hatten von Valgors Absichten erfahren und schworen, das
          Böse ein für alle Mal zu vernichten.
        </i>
      </p>

      <div class="flex-img-container">
        <a :href="img[0]" v-for="img in conImages[0]">
          <img :src="img[1]" />
        </a>
      </div>

      <p></p>
      <h3>OT-Beschreibung</h3>
      <p>
        Die nächste Veranstaltung wird diesmal keine PvP-Con werden, sondern
        eine große Belagerung mit GSC und NSC (und natürlich SC). Wir werden
        dafür sorgen, dass es hier ein Gleichgewicht der Kräfte und einen
        spannenden Plot gibt. Wie immer wird es wieder viele Schlachten geben
        und wir bieten euch wie immer die Möglichkeit euer Belagerungsgerät an
        einer echten Burgruine auszuspielen. Für das leibliche Wohl sorgen
        unsere bis zu zwei Tavernen. Das genaue Angebot werden wir dann
        rechtzeitig in unseren berühmten Infomails bekannt geben. Um das
        Gleichgewicht zu halten haben wir ein spezielles Anmeldeverfahren, das
        weiter unten näher erläutert wird.
      </p>
      <div
        style="
          background-color: rgba(255, 0, 0, 0.2);
          border-style: solid;
          padding: 0px 30px 30px;
        "
      >
        <h3>Keine Warhammer-Pflicht</h3>
        Wir spielen zwar im Warhammer-Universum, aber
        <b>Nicht-Warhammer-Charaktere sind dennoch willkommen!</b>
        Ihr müsst nicht unbedingt in der Alten Welt angesiedelt sein, um bei uns
        mitzuspielen. Bringt gerne auch eure anderen
        Mittelalter-Fantasy-Charaktere mit.
      </div>
      <p></p>
      <p>
        <!-- Facebook -->
        <a
          href="https://www.facebook.com/Freizeitritter-107039264349344/"
          class="mx-2 fa-2xl"
          target="_blank"
        >
          <font-awesome-icon icon="fa-brands fa-facebook"></font-awesome-icon>
        </a>

        <!-- Instagram -->
        <a
          href="https://www.instagram.com/freizeitritter/"
          class="mx-2 fa-2xl"
          target="_blank"
        >
          <font-awesome-icon icon="fa-brands fa-instagram"></font-awesome-icon>
        </a>
      </p>
      <p>Wir freuen uns auf eure Anmeldungen!</p>
      <p>Alle wichtigen Infos im Folgenden.</p>

      <div class="flex-img-container">
        <a :href="img[0]" v-for="img in conImages[1]">
          <img :src="img[1]" />
        </a>
      </div>

      <h3>Anmeldedaten</h3>
      <table class="table table-sm mt-3 mb-0">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Ort</th>
            <th>Spielermenge</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01.05. - 04.05.2025</td>
            <td>67823<br />Burgruine<br />Obermoschel</td>
            <td>
              150 SC<br />
              40 GSC<br />
              40 NSC
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Anmeldeverfahren</h3>
      <p>
        Neben dem normalen Auswählen der Tickets (SC, NSC, GSC) kommt ein
        weiterer Punkt in unserem Anmeldeprozess auf euch zu: Nachdem ihr den
        Anmeldeprozess gestartet habt, durchlauft ihr wie gehabt einen
        Bewerbungsprozess. Nachdem wir eure Charakterbögen gesichtet haben,
        werden wir ein Gespräch mit euch führen, um abzustecken, was ihr euch
        von unserer Con wünscht und was wir so erwarten. Außerdem werden wir
        Fragen stellen, die sich aus den Charakterbögen ergeben, um euer und das
        Spielerlebnis aller Teilnehmer optimal zu gestalten.
      </p>
      <p>
        <b>Wie genau melde ich mich an?</b>
      </p>

      <p>
        Die Anmeldung erfolgt via Mail (<a href="mailto:info@freizeitritter.de"
          >info@freizeitritter.de</a
        >) oder über den untenstehenden Knopf.
      </p>
      <p>
        Wir haben einen umfangreichen Anmelde-/Charakterbogen. Wir bitten
        euch, diesen so ausführlich wie möglich auszufüllen. So können wir
        spannendere Plots für euch kreieren. Bitte beachtet, dass die Kopfzeile
        - also die OT-Informationen - Pflichtfelder sind.
      </p>
      <p>
        <i>Hinweis zur Warteliste:</i><br />
        Wir werden nach und nach SC-Plätze freigeben, sobald sich NSCs oder GSCs
        angemeldet haben. Sobald ein gewisses Kontigent an NSC oder GSC
        angemeldet sind, werdet ihr von uns benachrichtigt. Anschließend habt ihr 10
        Tage Zeit, um euch final anzumelden. Danach rutscht ihr wieder auf die
        Warteliste und andere SC bekommen die Chance auf das Ticket. Die genauen
        Infos folgen dann via Mail von uns.
      </p>

      <p>
        <b> Der Ablauf im Überblick </b>
      </p>
      <ol style="text-align: start; text-align-last: start;">
        <li>
          Ihr schreibt uns eine Mail mit der Absicht der Anmeldung und eurer
          Teilnehmeranzahl.
        </li>
        <li>
          Wir teilen euch mit, ob genug Plätze frei sind, oder ob ihr auf die
          Warteliste kommt, bis genug NSC/GSC dabei sind. Außerdem schicken wir
          euch schonmal die Charakterbögen zum ausfüllen.
        </li>
        <li>Ihr meldet euch zurück mit den ausgefüllten Charakterbögen.</li>
        <li>
          Wir schicken euch einen Terminvorschlag für das Interview, falls wir
          euch noch nicht kennen.
        Gerne bei uns auf dem
        <a href="https://discord.gg/fqrEPQABVT" target="_blank"
          >Discord-Server</a
        >. Falls das nicht klappt, können wir auch telefonisch reden.
        </li>
        <li>
          Sobald ihr einen Platz auf der Con zugesprochen bekommen habt, schickt
          ihr uns den Con-Beitrag.
        </li>
        <li>Ihr seid erfolgreich angemeldet!</li>
      </ol>

      <h3>Magie? Magie!</h3>
      <p>
        Dieses Jahr werden wir auf unserer Belagerungs-Conreihe "Blutgetränkte
        Erde", anders als in den letzten Jahren, Magie zulassen und allerlei
        schöne Darstellungen davon werden sehr gerne gesehen.
      </p>

      <h3>NSC, GSC und Festrollen</h3>
      <p>
        Wir bieten unseren NSC die ganze Con über kostenlose Verpflegung sowie
        nicht-alkoholische Getränke an.
      </p>

      <h3>Regelwerk & FAQ</h3>
      <p>
        Da im Laufe der Zeit einige Fragen rund um den Ablauf und das Verhalten
        auf der Con aufgekommen sind, haben wir die wichtigsten Antworten in
        einem Dokument zusammengefasst. Dieses Dokument werden wir stetig
        erweitern. Auf der ersten Seite seht ihr jeweils die aktuelle Version.
        Teilnehmer werden aber natürlich alle wichtigen Informationen zusätzlich
        per Mail erhalten.
      </p>

      <a href="static/Freizeitritter-Regelwerk.pdf" target="_blank">
        <button class="btn btn-default">Zum Regelwerk</button>
      </a>

      <!-- TODO: auto-highlight currently active row (table-active) -->
      <table class="table table-sm my-4">
        <thead>
          <tr>
            <th>Anmeldezeitpunkt</th>
            <th>SC</th>
            <th>GSC</th>
            <th>NSC</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>August - November 2024</td>
            <td>120 EUR</td>
            <td>90 EUR</td>
            <td>20 EUR</td>
          </tr>
          <tr>
            <td>Dezember 2024 - Februar 2025</td>
            <td>140 EUR</td>
            <td>110 EUR</td>
            <td>30 EUR</td>
          </tr>
          <tr>
            <td>März - 20. April 2025</td>
            <td>150 EUR</td>
            <td>120 EUR</td>
            <td>40 EUR</td>
          </tr>
          <tr>
            <td>Ab 21. April 2024 und Conzahler nur nach Absprache</td>
            <td>+20 EUR</td>
            <td>+10 EUR</td>
            <td>+10 EUR</td>
          </tr>
        </tbody>
      </table>

      <form class="container">
        <div class="row">
          <input
            type="text"
            class="col-12"
            placeholder="Gruppenname"
            v-model="groupName"
          />
          <input
            type="number"
            class="col-12"
            placeholder="Spieler"
            v-model="amount"
          />
          <input
            type="button"
            value="Jetzt anmelden"
            @click="submitMail"
            class="btn btn-default btn-lg col-12"
          />
        </div>
      </form>

      <!-- Fallback-info for registration -->
      Der Anmelden-Knopf funktioniert nicht?
      <a href="javascript:void(0);" @click="fallbackShown = true">
        Dann klicke hier!
      </a>

      <p v-if="fallbackShown">
        Zum <b>Anmelden</b> sende bitte folgende E-Mail an
        <a :href="'mailto:' + generateMail().address">{{
          generateMail().address
        }}</a
        >. Wir kommen dann mit dem Rest auf euch zu.<br />
        <br />
        <b>Betreff:</b><br />
        {{ generateMail().subject }}<br />
        <br />
        <b>Inhalt:</b><br />
        {{ generateMail().body }}
      </p>

      <p></p>

      <div class="flex-img-container">
        <a :href="img[0]" v-for="img in conImages[2]">
          <img :src="img[1]" />
        </a>
      </div>

      <p></p>

      <div class="flex-img-container">
        <a :href="img[0]" v-for="img in conImages[3]">
          <img :src="img[1]" />
        </a>
      </div>

      <h3>Weitere Cons und Archiv</h3>
      <p>
        <a href="https://freizeitritter.de/" target="_blank">Hier</a>
        gehts zur Freizeitritter-Webseite, auf der ihr unsere anderen Cons und
        unser Archiv findet.
      </p>

      <p class="mt-5">
        Siehe auch:
        <a
          target="_blank"
          href="https://www.larpkalender.de/termine/index.html?aktion=suche&con_id=10886&detailsuche=0"
          >Eintrag auf Thilo Wagners Larp-Kalendar</a
        >
      </p>
    </div>
    <div class="modal fade" id="registrationFallbackModal" tabindex="0">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Anmeldung</h5>
          </div>
          <div class="modal-body">
            <p>
              Sende folgende E-Mail an
              <b>{{ this.generateMail().address }}</b>
            </p>

            <b>Betreff</b>
            <pre>{{ this.generateMail().subject }}</pre>

            <b>Inhalt</b><br />

            <pre
              class="border border-primary rounded p-2"
            ><code>{{ this.generateMail().body }}</code></pre>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionCon",

  data() {
    return {
      groupName: "",
      amount: "",
      views: null,
      fallbackShown: false,
      conImages: [
        [
          [
            require("../img/con-img/BGE26.jpg"),
            new URL("../img/con-img/BGE26.jpg?width=600", import.meta.url),
          ],
        ],
        [
          [
            require("../img/con-img/BGE123.jpg"),
            new URL("../img/con-img/BGE123.jpg?width=600", import.meta.url),
          ],
          [
            require("../img/con-img/BGE145.jpg"),
            new URL("../img/con-img/BGE145.jpg?width=600", import.meta.url),
          ],
        ],
        [
          [
            require("../img/con-img/BGE19.jpg"),
            new URL("../img/con-img/BGE19.jpg?width=600", import.meta.url),
          ],
        ],
        [
          [
            require("../img/con-img/BGE134.jpg"),
            new URL("../img/con-img/BGE134.jpg?width=600", import.meta.url),
          ],
          [
            require("../img/con-img/BGE176.jpg"),
            new URL("../img/con-img/BGE176.jpg?width=600", import.meta.url),
          ],
        ],
      ],
    };
  },

  methods: {
    generateMail() {
      const address = "info@freizeitritter.de";
      const subject = "Anmeldung Blutgetränkte Erde 2025";
      const body =
        `Gruppenname: ${this.groupName}\n` + `Anzahl: ${this.amount}\n`;

      return { address, subject, body };
    },

    submitMail() {
      const mail = this.generateMail();
      window.location =
        "mailto:" +
        mail.address +
        "?subject=" +
        mail.subject +
        "&body=" +
        encodeURIComponent(mail.body);
    },
  },
  mounted() {
    // properly scale images based on their aspect ratio
    // https://stackoverflow.com/questions/26434523/making-responsive-images-with-different-aspect-ratios-the-same-height#comment120909073_61002855
    document.querySelectorAll(".flex-img-container a").forEach((imgLink) => {
      const img = imgLink.firstElementChild;
      img.addEventListener("load", (event) => {
        const ratio = img.naturalWidth / img.naturalHeight;
        imgLink.style.flex = ratio;
      });
    });
  },
};
</script>
