// Load stylesheets
require("bootstrap");
require("./scss/main.scss");
// import "leaflet/dist/leaflet.css";

import { library } from "@fortawesome/fontawesome-svg-core";

import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faFacebook);
library.add(faInstagram);

import { createApp } from "vue";

import App from "./App.vue";

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
