<template>
  <header-intro></header-intro>
  <div class="container-fluid p-0">
    <section-con></section-con>
    <!--    <section-map></section-map>-->
  </div>
  <footer-impressum></footer-impressum>
</template>

<script>
import FooterImpressum from "./components/FooterImpressum.vue";
import HeaderIntro from "./components/HeaderIntro.vue";
import SectionCon from "./components/SectionCon.vue";
// import SectionMap from "./components/SectionMap.vue";
export default {
  name: "App",
  components: {
    FooterImpressum,
    // SectionMap,
    SectionCon,
    HeaderIntro,
  },
};
</script>
