<template>
  <header class="masthead">
    <div class="intro-body" id="page-top"></div>
  </header>
</template>

<script>
export default {
  name: "HeaderIntro",
};
</script>
